import { Injectable } from '@angular/core';

@Injectable()
export class PasswordProtector {
  private readonly obfuscatedPassword = 'c3RpbGxtYW4=';

  public locked = true;

  public constructor() {
    this.locked = !sessionStorage.getItem('locked');
  }

  public unlock(password: string) {
    if (btoa(password) === this.obfuscatedPassword) {
      this.locked = false;
      sessionStorage.setItem('locked', 'false');
      return true;
    }
    return false;
  }
}
