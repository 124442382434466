export const environment = {
  RECAPTCHA_SITE_KEY: '6LdXV8cpAAAAAMa5fieHqhi0KLvSlwtW-A6A-UzC',
  production: true,
  SUMSUB: {
    LINK: {
      COMPANY_US: 'https://in.sumsub.com/idensic/l/#/uni_9difR7Li8LI5sXum',
      PRIVATE_US: 'https://in.sumsub.com/websdk/p/uni_0hSNgQsB83sdUbWW',
      COMPANY_WORLD: 'https://in.sumsub.com/idensic/l/#/uni_9difR7Li8LI5sXum',
      PRIVATE_WORLD: 'https://in.sumsub.com/websdk/p/uni_0hSNgQsB83sdUbWW',
    },
  },
  DOCUSIGN: {
    CLIENT_ID: 'b7a830e98569708707e499842f17100f',
    API_KEY: '81620191c5a417abcc28e3de390f49ccd479edbcdba462f3201a8e7a0fd9f61e',
    CC_EMAIL: 'sales@stillmandigital.com',
    TEMPLATE_IDS: {
      COMPANY_US: 'a0ee94134eb47db62f94457a05f83297c44ddd38',
      PRIVATE_US: '864b9b3423bdf93f2723bdb8d44b9cd8a327f9a8',
      COMPANY_WORLD: '5e5fa8db944e9e8a3c6592f1da28f3b67eeabe8b',
      PRIVATE_WORLD: '5e5fa8db944e9e8a3c6592f1da28f3b67eeabe8b',
    },
  },
};
