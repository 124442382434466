import { Routes } from '@angular/router';
import { checkRegion, loggedIn, loggedOut } from './route.validators';

export const routes: Routes = [
  {
    path: 'password',
    canActivate: [loggedOut],
    loadComponent: () =>
      import('./password/password.component').then(
        (component) => component.PasswordComponent
      ),
  },
  {
    path: '',
    canActivateChild: [loggedIn],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./signup-root/signup-root.component').then(
            (component) => component.SignupRootComponent
          ),
      },
      {
        path: 'company',
        data: { applicationType: 'company' },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './select-jurisdiction/select-jurisdiction.component'
              ).then((component) => component.SelectJurisdictionComponent),
          },
          {
            path: ':region',
            canActivate: [checkRegion],
            loadComponent: () =>
              import('./company-signup/company-signup.component').then(
                (component) => component.CompanySignupComponent
              ),
          },
          {
            path: ':region/verification',
            canActivate: [checkRegion],
            loadComponent: () =>
              import(
                './verification-company/verification-company.component'
              ).then((component) => component.VerificationCompanyComponent),
          },
        ],
      },
      {
        path: 'individual',
        data: { applicationType: 'individual' },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './select-jurisdiction/select-jurisdiction.component'
              ).then((component) => component.SelectJurisdictionComponent),
          },
          {
            path: ':region',
            canActivate: [checkRegion],
            loadComponent: () =>
              import('./individual-signup/individual-signup.component').then(
                (component) => component.IndividualSignupComponent
              ),
          },
          {
            path: ':region/verification',
            canActivate: [checkRegion],
            loadComponent: () =>
              import(
                './verification-individual/verification-individual.component'
              ).then((component) => component.VerificationIndividualComponent),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
