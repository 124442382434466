import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PasswordProtector } from './utils/passwordProtector.service';

export const checkRegion: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const region = route.paramMap.get('region');
  const supportedRegions = ['usa', 'canada', 'world'];

  return (
    (region && supportedRegions.includes(region)) ||
    inject(Router).createUrlTree(['/'])
  );
};

export const loggedIn: CanActivateFn = (route, state) =>
  !inject(PasswordProtector).locked ||
  inject(Router).createUrlTree(['/password'], {
    queryParams: { returnUrl: state.url },
  });

export const loggedOut: CanActivateFn = () =>
  inject(PasswordProtector).locked || inject(Router).createUrlTree(['/']);
